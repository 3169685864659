
import { Vue, Component, Prop } from 'vue-property-decorator';
import LineChart from '@/components/Chart/LineChart.vue';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import BaseChart from '@/components/Chart/BaseChart.vue';
import { ApexOptions } from 'apexcharts';
import Message from '@/components/mixins/Message.vue';
import { DocumentNode } from 'graphql';
import EventBus from '@/event-bus';
import { FetchPolicy } from 'apollo-client';

type ChartType = 'LineChart'|'BaseChart';

@Component({
  name: 'ChartBlock',
  components: {
    BaseCard,
    BaseChart,
    LineChart,
  },
})
export default class ChartBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  protected readonly navigationSlug!: string;

  @Prop({ required: true })
  protected readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  protected readonly dossierId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  private loading = false;

  private chartType: ChartType|null = null;

  private options: ApexOptions = { };

  private series: ApexOptions['series'] = [];

  mounted(): void {
    this.fetchData();

    const forceRerender = () => this.fetchData('network-only');

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    const variables = {
      dossierId: this.dossierId,
      blockId: this.blockId,
      navigationSlug: this.navigationSlug,
      dossierTypeSlug: this.dossierTypeSlug,
    };

    this.import()
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
        fetchPolicy,
      }))
      .then((response) => {
        const graphData = response.data.graph.data;
        this.series = graphData.series;
        this.options = graphData;

        switch (this.options?.chart?.type) {
          case 'line': this.chartType = 'LineChart';
            break;
          default:
            this.chartType = 'BaseChart';
        }
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private import(): Promise<{ default: DocumentNode }> {
    switch (this.$route.name) {
      case 'dashboard': return import('@/graphql/queries/dashboard-graph-block');
      case 'dossier-detail': return import('@/graphql/queries/dossier-graph-block-by-block-id');
      default: throw Error(`no graphQL endpoint found for the ChartBlock. Current route name '${this.$route.name}'`);
    }
  }
}
