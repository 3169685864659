
import BaseChart from '@/components/Chart/BaseChart.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';

@Component({
  name: 'LineChart',
  components: {
    BaseChart,
  },
})
export default class LineChart extends Vue {
  @Prop({ required: true })
  private readonly options!: ApexOptions;

  @Prop({ required: true })
  private readonly series!: ApexOptions['series'];
}
