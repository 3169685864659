

import { Vue, Component, Prop } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';

@Component({
  name: 'BaseChart',
})
export default class BaseChart extends Vue {
  @Prop({ required: true })
  private readonly options!: ApexOptions;

  @Prop({ required: true })
  private readonly series!: ApexOptions['series'];
}
